'use client'
import {useEffect, useState} from 'react'

type Props = {
  allHeaders: any[]
}

const ArticleOutline = ({ allHeaders }: Props) => {
  const [currentId, setCurrentId] = useState<string>('')
  
  useEffect(()=> {
    const sections = document.querySelectorAll(".header-section");
    
    const handleScroll = (e: any) => {
      sections.forEach((section) => {
        const boundReact = section.getBoundingClientRect()
        const sectionTop = boundReact.top;
        
        if (window.innerHeight >= sectionTop - 60) {
          setCurrentId(section.id)
        }
      });
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
    }, [])

  return (
    (allHeaders && allHeaders.length > 0) ? (
      <div className="left-side-content-menu flex flex-col items-start text-left">
        <div className='sticky left-0 top-24 bottom-auto pl-10 pr-6 py-4'>
          <ol>
            {allHeaders.map((headerVal, index)=> (
              <li key={index} className={`${headerVal.divId === currentId ? 'active' : ''}`}><a href={`#${headerVal.divId}`} className={`${"font-albert font-normal text-base text-[#263D42] no-underline hover:text-[#0C671A] hover:underline"}`}>{headerVal.heading}</a></li>
            ))}
          </ol>
          <h6 className="font-albert font-normal text-base text-[#151617] block mt-10">Share:</h6>
          <ul className="social-wrap flex flex-wrap mb-5">
            <li className="faceboox">
                <a href="#">Faceboox</a>
            </li>
            <li className="twitter">
                <a href="#">Twitter</a>
            </li>
            <li className="reddit">
                <a href="#">Reddit</a>
            </li>
            <li className="linkedin">
                <a href="#">Linkedin</a>
            </li>
            <li className="mail">
                <a href="#">Mail</a>
            </li>
          </ul>          
        </div>
      </div>
    ) : null
  )
}

export default ArticleOutline