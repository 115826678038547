'use client'
import React, { Fragment, useState } from 'react'
import { BlogPagination, OnPageChangeCallback } from '@/components/blog/BlogPagination'
import ArticleCardItem from '../core/ArticleCardItem'
import { BlogPostEntity } from '@/gql/generated/graphql'

type Props = {
  blog_posts: BlogPostEntity[]
}

const CategoryPaginationWrapper = ({blog_posts}: Props) => {

  const itemsPerPage = 9
  const [itemOffset, setItemOffset] = useState(0);
  
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = blog_posts.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(blog_posts.length / itemsPerPage);

  const onPageChanged: OnPageChangeCallback = selectedItem => {
    const newPage = selectedItem.selected
    const newOffset = (newPage * itemsPerPage) % blog_posts.length;
    setItemOffset(newOffset);
  }

  return (
    <Fragment>
      <div className="articleCard-group sm:flex sm:flex-wrap">
        {currentItems?.map((article, index) => (
          <div key={`recent-articleCard-box${index}`} className='articleCard-box group w-full'>
            <ArticleCardItem article={article} key={index} />
          </div>
        ))}
      </div>
      <nav className="pagination flex justify-center w-full">
        <BlogPagination 
          pageCount={pageCount} 
          onPageChange={onPageChanged} />
      </nav>
    </Fragment>
  )
}

export default CategoryPaginationWrapper