'use client'

import React, { FC, Fragment } from 'react'
import ReactPaginate, { ReactPaginateProps } from 'react-paginate'

export type OnPageChangeCallback = ReactPaginateProps['onPageChange']

type Props = {
  pageCount: number
  onPageChange?: OnPageChangeCallback
}

export const BlogPagination: FC<Props> = ({ pageCount, onPageChange }) => {
  
  return (
    <Fragment>
      <ReactPaginate
        pageCount={pageCount}
        containerClassName='flex flex-wrap items-center gap-x-4 gap-y-3'
        previousLinkClassName='font-josefin font-bold text-base text-[#2C2D2E] h-8 flex justify-center items-center'
        nextLinkClassName='font-josefin font-bold text-base text-[#2C2D2E] h-8 flex justify-center items-center'
        pageLinkClassName='font-josefin font-bold text-base text-[#2C2D2E] h-8 flex justify-center items-center'
        nextLabel='Next'
        previousLabel='Previous'
        breakLabel='...'
        pageRangeDisplayed={4}
        activeLinkClassName='active font-josefin font-bold text-base text-[#2C2D2E] h-8 flex justify-center items-center'
        disabledClassName=''
        disabledLinkClassName=''
        onPageChange={onPageChange}
        renderOnZeroPageCount={() => null}
      />
    </Fragment>
  )
}
