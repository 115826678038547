'use client'
import React from 'react'
import ShareSocial from '@/components/blog/ShareSocial'
import ArticleOutline from '@/components/blog/ArticleOutline'
import Image, { ImageLoaderProps } from 'next/image'
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { IMAGE_ENDPOINT } from '@/env'
import { BlogPostEntity } from '@/gql/generated/graphql';
import ResponsiveImage from '@/components/core/ResponsiveImage';
import SlugifyString from '@/utils/SlugifyString'

type Props = {
  post: BlogPostEntity
}

const ArticleDetails = ({post}: Props) => {
  
  let allHeaders: any[] = []

  const customLoader = ({ src, width }: ImageLoaderProps) => {
    let replaceExt = src.replaceAll(/\.(png|jpg|jpeg)$/g, '.webp');
    return `${IMAGE_ENDPOINT}/${width}w_${replaceExt}`
  }

  const options: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      if ((domNode.name === 'h2' || domNode.name === 'h3') && domNode.children) {
        let textNode = domNode.children[0].data
        // Consider strong inside h2
        if (domNode.children[0].type === 'tag' && domNode.children[0].name === 'strong'){
          textNode = domNode.children[0].children[0].data
        }
        
        let generateId = SlugifyString({ str: textNode });
        allHeaders.push({ divId: generateId, heading: textNode })
        domNode.attribs["id"] = generateId
        domNode.attribs["class"] = 'header-section'
      }
      if (domNode.name === 'img') {
        let src = domNode.attribs.src.replace(`${IMAGE_ENDPOINT}/`, '')
        domNode.attribs['src'] = src
        return (
          <Image
            src={src}
            loader={customLoader}
            width={domNode.attribs.width}
            height={domNode.attribs.height}
            alt={domNode.attribs.alt}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />
        )
      }
      return domNode
    }
  };

  const parsed = parse(post.attributes?.content ?? "", options)

  return (
    <div className="article-details md:flex">
      <div className="contentCol max-w-[700px] mx-auto">
        <div className="flex flex-wrap ">
          <ArticleOutline allHeaders={allHeaders} />
          <div className="content-block w-full flex flex-col items-start text-left prose-img:mb-8 prose-h6:text-[13px] prose-h6:text-[#888888] prose-h6:-mt-[30px] prose-h3:text-2xl prose-h3:font-josefin prose-ul:mb-4 prose-li:mb-2.5 prose-h2:text-black prose-h2:text-[28px] prose-h2:font-josefin relative text-lg font-albert prose-blockquote:bg-[#F0FBF9] prose-blockquote:border-l-8 prose-blockquote:border-[#00A7A5]">
            {/* Detailed Content from Editor */}
            {/* <div dangerouslySetInnerHTML={{ __html: post.attributes?.content ?? "" }}></div> */}
            {post.attributes?.featureImage.data &&
              <ResponsiveImage image={post.attributes?.featureImage} className='w-full' />
            }
            {parsed}
            { /* <ShareSocial post={post} /> */ }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleDetails