'use client'
import React, { Fragment, useState } from 'react'
import { BlogPagination, OnPageChangeCallback } from '@/components/blog/BlogPagination'
import ArticleCardItem from '../core/ArticleCardItem'
import { BlogPostEntity } from '@/gql/generated/graphql'

type Props = {
  blog_posts: BlogPostEntity[]
}

const AuthorPaginationWrapper = ({blog_posts}: Props) => {

  const itemsPerPage = 12
  const [itemOffset, setItemOffset] = useState(0);
  
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = blog_posts.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(blog_posts.length / itemsPerPage);

  const onPageChanged: OnPageChangeCallback = selectedItem => {
    const newPage = selectedItem.selected
    const newOffset = (newPage * itemsPerPage) % blog_posts.length;
    setItemOffset(newOffset);
  }

  return (
    <Fragment>
      <section className="articleCard-wrap py-10 md:py-14">
        <div className="container">
            <h3 className="font-semibold text-3xl md:text-4xl text-[#252525] mb-7">Most Recent</h3>
            <div className="articleCard-group sm:flex sm:flex-wrap">
                {currentItems?.map((article, index) => (
                  <div key={index} className='articleCard-box group w-full'>
                    <ArticleCardItem article={article} key={index} />
                  </div>
                ))}
              <nav className="pagination flex justify-center w-full">
                <BlogPagination 
                  pageCount={pageCount} 
                  onPageChange={onPageChanged} />
              </nav>                
            </div>
        </div>
      </section>
    </Fragment>
  )
}

export default AuthorPaginationWrapper