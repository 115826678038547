'use client'
import React, { Fragment } from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BlogPostEntity } from '@/gql/generated/graphql';
import ArticleCardItem from './ArticleCardItem';

type Props = {
  blog_posts: BlogPostEntity[]
}

const ArticleSlider = (props: Props) => {
  const sliderSettings = {
    dots: false,
    infinite: props.blog_posts.length > 3,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <Fragment>
      <Slider {...sliderSettings} >
        {props.blog_posts.map((blogPost, index) => (
          <div key={index} className="articleCard-box group w-full">
            <ArticleCardItem
              article={blogPost}
            />
          </div>
        ))}
      </Slider>
    </Fragment>
  )
}

export default ArticleSlider